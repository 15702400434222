import Countdown from '/components/Countdown';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { getMultiProductDetails } from '/services/static/promotions/multi-product';
import { pluralize } from '/utils/pluralize';
import { useGlobalStore } from '/state/global-context';

import { useEffect, useState } from 'react';

const MoneyBackCartDetail = ({ processedCart }) => {
	const [multiProductDetails, setMultiProductDetails] = useState();
	const globalStore = useGlobalStore();
	const currentPromotion = globalStore.getPromotionalConfig();

	useEffect(() => {
		const multiProduct = getMultiProductDetails(processedCart.processedLineItems, currentPromotion);
		setMultiProductDetails(multiProduct);
	}, [processedCart.processedLineItems]);

	if (
		!currentPromotion ||
		currentPromotion.promotionType !== 'multi-product' ||
		!multiProductDetails
	) {
		return null;
	}

	const showRemainingPairs =
		multiProductDetails.emptyCart === false && multiProductDetails.quantityNeeded != 1;

	return (
		<div
			className="bg-gray-600 text-center py-2"
			style={{
				backgroundColor: currentPromotion.sitewideBanner.colorPrimary,
				color: currentPromotion.sitewideBanner.colorSecondary,
			}}>
			{/* user has nothing in cart */}
			{multiProductDetails.emptyCart && (
				<Typography component="div">Buy Any 3 Underwear, Get A 4th Free</Typography>
			)}

			{/* user needs to add pairs to get to the next free pair */}
			{showRemainingPairs && (
				<Typography component="div">
					Add {multiProductDetails.quantityNeeded - 1} More{' '}
					{pluralize('Pair', multiProductDetails.quantityNeeded)} To Get{' '}
					{currentPromotion.quantityFree} {multiProductDetails.quantityFreeAwarded > 0 && 'More '}
					Free
				</Typography>
			)}

			{/* user can add a free pair to their cart */}
			{multiProductDetails.quantityNeeded == 1 && (
				<Typography component="div">Free Underwear Unlocked, Add A Pair Now</Typography>
			)}
			<div className="text-white">
				Sale ends in <Countdown countdownDate={currentPromotion.dateEnd} />
			</div>
		</div>
	);
};

MoneyBackCartDetail.propTypes = {
	processedCart: PropTypes.object,
};

export default MoneyBackCartDetail;
