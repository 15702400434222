import axios from 'axios';

const filterRecommendations = (recommendations) => {
	const filtered = [];
	recommendations?.map((item) => {
		if (!item?.title.includes('Subscriptions')) {
			filtered.push(item);
		}
	});

	return filtered;
};

async function getRecommendations(productIds) {
	const res = await axios({
		method: 'get',
		url: `${process.env.NEXT_PUBLIC_SHINE_PUBLIC}/product-recommendation`,
		params: {
			sourceProductIds: productIds,
		},
	});
	return res.data;
}

module.exports = {
	filterRecommendations,
	getRecommendations,
};
