import Button from '/components/Button';
import Clickable from '../../Clickable';
import Icon from '/components/IconUpdated';
import Image from '/components/Image';
import Loading from '/components/Loading';
import ProductOptions from '../../products/show/product-form/ProductOptions';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { getProductById } from '/services/products';
import { linesFromVariants } from '/utils/product';
import { logTryCatch } from '/utils/logging';
import { parseGid } from '/utils/graph-ql';
import { useCartStore } from '/state/cart-context';
import {
	CAN_CHANGE_QUANTITY,
	PROMOTIONAL_ITEM,
	PROMOTIONAL_ITEM_INTERACTION,
	PROMOTIONAL_TIER,
} from '/services/static/attribute-keys';
import { useEffect, useState } from 'react';

const TierForm = ({ allTiers, setModalOpen, tier, totalTiers }) => {
	const cartStore = useCartStore();
	const [currentTier, setCurrentTier] = useState(tier);
	const [productsToShow, setProductsToShow] = useState([]);
	const [currentTierProduct, setCurrentTierProduct] = useState();
	const [primaryProduct, setPrimaryProduct] = useState();
	const [isBackup, setIsBackup] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [selectedProductType, setSelectedProductType] = useState();
	const [selectedBackup, setSelectedBackup] = useState();
	const [soldOutSizes, setSoldOutSizes] = useState([]);
	const isSwappableBackup =
		currentTierProduct &&
		currentTierProduct?.backups[0]?.isSwappable &&
		currentTierProduct?.backups[0].swappableProducts &&
		isBackup;

	// const [addToCartError, setAddToCartError] = useState(false);
	const [addToCartLoading, setAddToCartLoading] = useState(false);

	useEffect(() => {
		setProduct([currentTier?.productConfigs[0].primary.id], false);
		setSelectedProductType(currentTier?.productConfigs[0].primary.productType);
	}, [currentTier]);

	useEffect(() => {
		if (
			!productsToShow ||
			!productsToShow[0]?.variants ||
			productsToShow[0].variants.length === 0
		) {
			return;
		}
		setSelectedOptions([]);

		const soldOutSizeTitles = productsToShow[0].variants.reduce((memo, variant) => {
			if (variant.quantityAvailable === 0) {
				memo.push(variant.title);
			}

			return memo;
		}, []);

		setSoldOutSizes(soldOutSizeTitles);
	}, [productsToShow]);

	const addToCart = async () => {
		// setAddToCartError();
		setAddToCartLoading(true);

		const lineAttributes = [
			{ key: CAN_CHANGE_QUANTITY, value: 'false' },
			{ key: PROMOTIONAL_ITEM, value: 'true' },
			{ key: PROMOTIONAL_ITEM_INTERACTION, value: 'true' },
			{ key: PROMOTIONAL_TIER, value: currentTier.title },
		];

		const variantLineData = selectedOptions.map((option) => {
			return {
				attributes: lineAttributes,
				price: option.product?.prices[0] || '',
				product: option.product,
				quantity: 1,
				variantId: option.variantId,
				variantTitle: option.variantTitle || '',
			};
		});

		const lines = linesFromVariants(variantLineData);

		await cartStore
			.addItems({
				error: () => {
					setAddToCartLoading(false);
					setModalOpen(false);
				},
				lines,
				success: () => {
					setAddToCartLoading(false);
					setModalOpen(false);
				},
			})
			.catch((e) => {
				setAddToCartLoading(false);
				logTryCatch(e);
				setModalOpen(false);
			});
	};

	const toggleBackupProduct = async () => {
		setSelectedOptions([]);
		setIsBackup(true);
		const currentId = parseGid(productsToShow[0].id);

		if (
			!productsToShow ||
			!currentId ||
			!currentTier.productConfigs ||
			currentTier.productConfigs.length === 0
		) {
			return;
		}

		const tierProduct = currentTier.productConfigs.find((tierProd) => {
			return tierProd.primary.handle === productsToShow[0].handle;
		});

		if (!tierProduct || !tierProduct.primary.id || !tierProduct.backups) {
			return;
		}

		setPrimaryProduct({
			id: currentId,
			title: productsToShow[0].titles[0],
		});

		let productIds = [tierProduct.backups[0].id];
		if (tierProduct?.backups[0]?.isMultiLine) {
			productIds.push(tierProduct?.backups[0].additionalLines[0]?.id);
		}
		if (
			currentTierProduct &&
			currentTierProduct?.backups[0]?.isSwappable &&
			currentTierProduct?.backups[0].swappableProducts
		) {
			setSelectedBackup(currentTierProduct?.backups[0].productType);
		}
		setProduct(productIds, true);
	};

	const togglePrimaryProduct = async () => {
		setSelectedOptions([]);
		setProduct([primaryProduct.id]);
		setIsBackup(false);
	};

	const setProduct = async (productIds, isBackup) => {
		const promises = productIds.map((productId) => {
			return getProductById(productId?.replace('gid://shopify/Product/', ''));
		});
		const products = await Promise.all(promises);
		//having totally different product types as backup kinda makes this moot
		// if (isBackup === true && products[0].variants.length != 1) {
		// 	products[0].variants = products[0].variants.filter((variant) => {
		// 		return soldOutSizes.includes(variant.title);
		// 	});
		// }

		if (!isBackup) {
			setPrimaryProduct();
		}

		setProductsToShow(products);

		const tierProduct = tier?.productConfigs?.find((tierProd) => {
			return (
				tierProd.primary.handle === products[0].handle ||
				tierProd.backups[0].handle === products[0].handle
			);
		});
		if (tierProduct) {
			setCurrentTierProduct(tierProduct);
		}
	};

	if (!tier) {
		return null;
	}

	function getProductIds(config) {
		const productIds = [config.id];
		if (config.isMultiLine && config.additionalLines[0]) {
			productIds.push(config.additionalLines[0]?.id);
		}
		return productIds;
	}

	function skipGift() {
		const currentIndex = allTiers.findIndex((tier) => tier.title === currentTier.title);
		if (totalTiers > 1 && currentIndex + 1 < totalTiers) {
			setCurrentTier(allTiers[currentIndex + 1]);
		} else {
			setModalOpen(false);
		}
	}

	return (
		<div className="flex flex-col bg-white pb-24 md:pb-0">
			{isSwappableBackup && (
				<div className="pl-4 md:pl-14">
					<Typography className="!text-base" component="h1" variant="heading-sm">
						Pick Your Item
					</Typography>
					<Button
						onClick={() => {
							setProduct(getProductIds(currentTierProduct?.backups[0]), false);
							setSelectedBackup(currentTierProduct?.backups[0].productType);
						}}
						className={classNames(
							'm-2 rounded ml-0 !shadow-none',
							selectedBackup === currentTierProduct?.backups[0].productType
								? '!bg-[#2F3337] !text-white'
								: '!bg-[#F2F3F5] !text-black',
						)}
						variant="filled-gray"
						type="button">
						{currentTierProduct?.backups[0].productType}
					</Button>
					<Button
						onClick={() => {
							setProduct(getProductIds(currentTierProduct?.backups[0].swappableProducts[0]), false);
							setSelectedBackup(currentTierProduct?.backups[0].swappableProducts[0].productType);
						}}
						className={classNames(
							'm-2 rounded ml-0 !shadow-none',
							selectedBackup === currentTierProduct?.backups[0].swappableProducts[0].productType
								? '!bg-[#2F3337] !text-white'
								: '!bg-[#F2F3F5] !text-black',
						)}
						variant="filled-gray"
						type="button">
						{currentTierProduct?.backups[0].swappableProducts[0].productType}
					</Button>
				</div>
			)}

			{productsToShow.map((currentProduct, i) => {
				return (
					<div className="flex flex-col md:flex-row pb-4 px-4" key={i}>
						<Typography
							className="italic md:hidden !leading-5 mb-2"
							component="h1"
							variant="heading-sm">
							{currentProduct?.titles[0] || currentTier.title}
						</Typography>

						<div className="flex items-start justify-start mb-4 md:justify-start">
							<Image
								alt={currentTier.title}
								objectFit="contain"
								src={
									currentProduct && currentProduct.images[0]
										? currentProduct.images[0].src
										: currentTier.imageSrcCover
								}
								height={320}
								width={320}
							/>
						</div>

						{currentProduct && (
							<Typography
								className="mb-4 max-w-md !leading-5 md:hidden"
								component="p"
								variant="body">
								{currentProduct.description}
							</Typography>
						)}
						<div className="md:pl-4">
							<Typography
								className="hidden italic !leading-5 mb-2 md:block"
								component="h1"
								variant="heading-sm">
								{currentProduct?.titles[0] || currentTier.title}
							</Typography>
							{currentProduct && (
								<Typography
									className="hidden md:block mb-4 max-w-md !leading-5"
									component="p"
									variant="body">
									{currentProduct.description}
								</Typography>
							)}
							{/* The tier allows a user to select their product */}
							{currentTier.productConfigs.length > 1 && (
								<div className="mb-4">
									<Typography className="!text-base" component="h1" variant="heading-sm">
										Pick Your Item
									</Typography>
									{currentTier.productConfigs.map((product, i) => {
										return (
											<Button
												key={i}
												onClick={() => {
													setProduct([product.primary.id], false);
													setSelectedProductType(product.primary.productType);
												}}
												className={classNames(
													'm-2 rounded ml-0 !shadow-none',
													selectedProductType === product.primary.productType
														? '!bg-[#2F3337] !text-white'
														: '!bg-[#F2F3F5] !text-black',
												)}
												variant="filled-gray"
												type="button">
												{product.primary.productType}
											</Button>
										);
									})}
								</div>
							)}
							{currentProduct && (
								<div className="max-w-md pb-20 md:pb-0">
									<ProductOptions
										product={currentProduct}
										selectedOptions={selectedOptions}
										setSelectedOptions={setSelectedOptions}
									/>
									{soldOutSizes.length > 0 && currentTierProduct && currentTierProduct.backups && (
										<Typography component="h1" variant="body">
											<Clickable
												className="text-secondary-dark underline capitalize"
												onClick={() => {
													toggleBackupProduct();
												}}>
												Don&apos;t see your size? Pick a different gift
											</Clickable>
										</Typography>
									)}
									{primaryProduct && i == 0 && (
										<Clickable
											className="font-semibold flex mt-2"
											onClick={() => {
												togglePrimaryProduct();
											}}>
											<Icon className="mt-1 mr-1" name="caretLeft" height="10px" width="10px" />
											<Typography
												className="text-secondary-dark text-left"
												component="p"
												variant="body">
												Nah, back to {primaryProduct.title}
											</Typography>
										</Clickable>
									)}
								</div>
							)}
						</div>
					</div>
				);
			})}

			<div className="bg-white fixed bottom-0 md:relative w-screen md:w-max flex flex-col justify-center items-center md:self-end p-4 z-20">
				<Clickable
					className="flex justify-center w-full"
					disabled={selectedOptions.length === 0}
					onClick={() => {
						addToCart();
					}}
					variant="filled">
					<Typography component="p" variant="body">
						{totalTiers > 1 ? 'Pick your next gift' : 'Add to Cart'}
					</Typography>
					{addToCartLoading && <Loading className="ml-2" />}
				</Clickable>
				<Clickable
					className="flex justify-center w-full"
					onClick={() => {
						skipGift();
					}}
					variant="inverted">
					<Typography component="p" variant="body">
						Decline Gift
					</Typography>
				</Clickable>
			</div>
		</div>
	);
};

TierForm.propTypes = {
	allTiers: PropTypes.array,
	setModalOpen: PropTypes.func,
	tier: PropTypes.object,
	totalTiers: PropTypes.number,
};

export default TierForm;
