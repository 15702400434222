import Clickable from '/components/Clickable';
import Image from '/components/Image';
import ProductOptionsStandard from '/components/products/show/product-form/ProductOptionsStandard';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { getButtonImageSrc } from '/services/products';
import { getCollection } from '/services/algolia';
import { getFirstProperty } from '/utils/array';
import { getProductById } from '/services/products';
import { getSubscriptionData } from '/services/static/subscription/subscriptions-ordergroove';
import { parseGid } from '/utils/graph-ql';
import { useDiscountCodeStore } from '../../state/discount-code-context';

import {
	CAN_CHANGE_QUANTITY,
	DISCOUNTED_LINE_PRICE,
	DISCOUNTED_SUB_PRICE,
	IS_FREE_ITEM,
	PROMOTIONAL_ITEM,
	PROMOTIONAL_ITEM_INTERACTION,
} from '/services/static/attribute-keys';

import { useEffect, useState } from 'react';

export default function SubscriptionAddon({ cart, processedCart }) {
	const discountCodeStore = useDiscountCodeStore();

	const [activeProduct, setActiveProduct] = useState();
	const [disableAddToCart, setDisableAddToCart] = useState(false);
	const [prints, setPrints] = useState([]);
	const [selectedOption, setSelectedOption] = useState([]);
	const [shopifyProduct, setShopifyProduct] = useState();
	const [collection, setCollection] = useState('heel-hammock-crew-socks');
	const freeGiftInCart = processedCart?.processedLineItems?.find((item) => {
		return item.attributes[IS_FREE_ITEM];
	});

	useEffect(() => {
		async function fetchPrints() {
			const algoliaResponse = await getCollection(collection);
			const formattedPrints = [];
			algoliaResponse.hits.map((print, printIndex) => {
				if (
					(print.shopifyProductType == 'socks - crew' ||
						print.shopifyProductType == 'socks - ankle') &&
					printIndex < 12
				) {
					formattedPrints.push(print);
				}
			});
			if (!activeProduct) {
				setActiveProduct(formattedPrints[0]);
			}
			setPrints(formattedPrints);
		}

		fetchPrints();
	}, [collection]);

	useEffect(() => {
		let regex = /crew-socks|ankle-socks|-1/g;
		const processedPrint = activeProduct?.handle?.replace(regex, '');
		if (processedPrint) {
			const selectedProduct = prints.find((print) => print.handle.includes(processedPrint));
			if (selectedProduct) {
				setActiveProduct(selectedProduct);
			} else {
				setActiveProduct(prints[0]);
			}
		}
	}, [prints]);

	useEffect(() => {
		if (!activeProduct?.shopifyProductId) {
			return;
		}
		async function getProduct() {
			const product = await getProductById(parseGid(activeProduct.shopifyProductId));
			setShopifyProduct(product);
		}
		setSelectedOption([]);
		getProduct();
	}, [activeProduct]);

	useEffect(() => {
		if (selectedOption && selectedOption.length > 0) {
			setDisableAddToCart(false);
		} else {
			setDisableAddToCart(true);
		}
	}, [selectedOption]);

	const planKey = 'monthly';
	function getSelectionData(product) {
		const selectedVariant = selectedOption && selectedOption.length ? selectedOption[0] : null;
		if (!selectedVariant) {
			return;
		}
		return {
			image: product.images[0].src,
			productType: product.productType,
			product_id: product.id,
			size: selectedVariant.variantTitle,
			title: product.title?.split(' | ')[0],
			variantId: selectedVariant.variantId,
			sellingPlan: product.sellingPlans.find((plan) => {
				return plan.key == planKey || plan.frequency == planKey;
			}),
			variantSku: selectedVariant.variantSku,
		};
	}
	async function addFreeSubscriptionToCart() {
		const selection = getSelectionData(shopifyProduct);

		const attributes = [
			{ key: PROMOTIONAL_ITEM, value: 'true' },
			{ key: CAN_CHANGE_QUANTITY, value: 'false' },
			{ key: IS_FREE_ITEM, value: 'true' },
			{ key: DISCOUNTED_SUB_PRICE, value: '0.00' },
			{ key: PROMOTIONAL_ITEM_INTERACTION, value: 'true' },
			{ key: DISCOUNTED_LINE_PRICE, value: '0.00' },
		];

		const subscriptionData = getSubscriptionData({
			attributes: attributes,
			discountCodeStore: discountCodeStore,
			frequencyKey: planKey,
			parentRequired: false,
			selections: [selection],
			sellingPlanId: shopifyProduct.sellingPlans.find((plan) => {
				return plan.key == planKey || plan.frequency == planKey;
			}).id,
			subscriptionType: 'single',
			isSocks: true,
			discountedSubPrice: 0,
		});

		const cartData = await cart
			.addItems({
				analytics: subscriptionData.analytics,
				error: () => {
					setDisableAddToCart(false);
				},
				lines: subscriptionData.lineItems,
				success: async () => {
					setDisableAddToCart(false);
				},
			})
			.catch((e) => {
				setDisableAddToCart(false);
				console.log(e);
			});

		return cartData;
	}

	if (freeGiftInCart || processedCart.subTotal == 0) {
		return null;
	}

	const productImage = getFirstProperty(activeProduct, 'images', 'imageSrc');

	return (
		<div className="p-2 border-2 m-2 rounded-lg grid gap-2">
			<Typography variant="heading-xs">
				Your cart qualifies for a free subscription add-on
			</Typography>
			{productImage && (
				<div className="grid">
					<Image
						src={productImage}
						alt={activeProduct.title}
						height={250}
						width={250}
						objectFit="contain"
					/>
				</div>
			)}
			<Typography className="mb-1" variant="heading-xs">
				Cuts
			</Typography>
			<div className="flex gap-2 mb-2">
				<button
					className={classNames(
						'px-4 py-2 rounded',
						collection.includes('crew') ? 'bg-gray-600 text-white' : 'bg-gray-50',
					)}
					onClick={() => setCollection('heel-hammock-crew-socks')}>
					<Typography variant="heading-xs">Crew</Typography>
				</button>
				<button
					className={classNames(
						'px-4 py-2 rounded',
						collection.includes('ankle') ? 'bg-gray-600 text-white' : 'bg-gray-50',
					)}
					onClick={() => setCollection('heel-hammock-ankle-socks')}>
					<Typography variant="heading-xs">Ankle</Typography>
				</button>
			</div>

			<Typography variant="heading-xs">Print</Typography>
			<div className="flex flex-wrap gap-1 mb-2">
				{prints.map((print, printIndex) => {
					const buttonImage = getButtonImageSrc(print);
					if (buttonImage) {
						return (
							<div className="flex flex-col relative" key={printIndex}>
								<button
									onClick={() => setActiveProduct(print)}
									className={classNames(
										'rounded-full h-[50px] w-[50px] m-px',
										activeProduct?.handle == print.handle
											? 'border-2 border-white ring-4 ring-gray-600 rounded-full hover:ring-gray-600'
											: 'hover:ring-gray-400 hover:border-2 border-white hover:ring-4',
									)}>
									<Image
										className="rounded-full"
										src={buttonImage}
										height={200}
										width={200}
										objectFit="contain"
										alt={print.handle}
									/>
								</button>
							</div>
						);
					}
				})}
			</div>
			{shopifyProduct && (
				<>
					<ProductOptionsStandard
						hasJoinedProducts={false}
						hasSingleSizeOption={false}
						product={shopifyProduct}
						selectedOptions={selectedOption}
						setSelectedOptions={setSelectedOption}
					/>
				</>
			)}
			<Clickable
				variant="filled"
				onClick={addFreeSubscriptionToCart}
				disabled={disableAddToCart}
				heapEventName="Free Sock Subscription Add On"
				heapEventData={{
					product: activeProduct?.handle,
				}}>
				Add Free Socks
			</Clickable>
			<Typography className="italic text-center">
				First month free, renews at <span className="font-semibold">9.99/mo</span>
			</Typography>
		</div>
	);
}
SubscriptionAddon.propTypes = {
	cart: PropTypes.object,
	processedCart: PropTypes.object,
	variant: PropTypes.string,
};
