import Image from '/components/Image';
import Price from '../Price';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { getNextTier } from '/services/static/promotions';
import { useGlobalStore } from '/state/global-context';
import { useEffect, useState } from 'react';

const StackingCartDetail = ({ processedCart }) => {
	const globalStore = useGlobalStore();
	const [nextTierDetails, setNextTierDetails] = useState({});

	useEffect(async () => {
		const nextTier = await getNextTier(processedCart.subTotal);
		setNextTierDetails(nextTier);
	}, [processedCart.subTotal]);

	const currentPromotion = globalStore.getPromotionalConfig();

	if (
		currentPromotion &&
		currentPromotion.promotionType == 'stacking-gifts' &&
		(!nextTierDetails || !nextTierDetails.tier) &&
		processedCart.subTotal > 100
	) {
		return (
			<div className="text-center py-2">
				<Typography variant="subhead-sm">All gifts added 🎉</Typography>
			</div>
		);
	}

	if (
		!currentPromotion ||
		currentPromotion.promotionType !== 'stacking-gifts' ||
		!nextTierDetails ||
		!nextTierDetails.tier
	) {
		return null;
	}

	return (
		<div className="text-center py-2">
			<Typography component="p" variant="body">
				You&apos;re only{' '}
				<Typography className="font-bold" variant="small">
					<Price price={nextTierDetails.priceDifference} showSymbol={true} />
				</Typography>{' '}
				away from your
			</Typography>
			<div
				className="flex mx-4 my-2 rounded-lg p-2 border-2"
				style={{ boxShadow: '0px 40px 32px -32px rgba(0, 0, 0, 0.25)' }}>
				<Image
					alt="gift"
					height={80}
					src={nextTierDetails.tier.imageSrcCover}
					width={80}
					objectFit="contain"
				/>
				<div className="text-left pl-4">
					<Typography className="text-primary italic" variant="heading-xs">
						Free gift{' '}
					</Typography>
					<Typography className="font-semibold !leading-[1rem]" component="div" variant="body">
						{nextTierDetails.tier.title}
					</Typography>
				</div>
			</div>
		</div>
	);
};

StackingCartDetail.propTypes = {
	processedCart: PropTypes.object,
};

export default StackingCartDetail;
