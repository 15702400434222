import Carousel from '/components/Carousel';
import Clickable from '/components/Clickable';
import Icon from '/components/IconUpdated';
import Image from '/components/Image';
import Loading from '/components/Loading';
import Price from '../Price';
import ProductType from '/components/products/show/product-form/ProductType';
import PropTypes from 'prop-types';
import SelectGroup from '/components/SelectGroup';
import Typography from '/components/Typography';
import { VALID_SITEWIDE_PACK_TYPES } from '/services/static/packs/build-a-pack';
import { getFirstProperty } from '/utils/array';
import { getProductById } from '/services/products';
import { getProductsShopify } from '/services/products';
import { getUpsellDiscount } from '/utils/cart-processor.js';
import { logTryCatch } from '/utils/logging';
import { useGlobalStore } from '/state/global-context';
import {
	BUILD_A_PACK,
	MINI_CART_UPSELL,
	PRODUCT_TYPE,
	SITEWIDE_PACK_BUILDER,
} from '/services/static/attribute-keys';
import {
	DEFAULT_PRICE_MENS_UNDERWEAR_DECIMAL,
	DEFAULT_PRICE_WOMENS_THONG_DECIMAL,
	DEFAULT_PRICE_WOMENS_UNDERWEAR_DECIMAL,
} from '/services/static/default-prices';
import { filterRecommendations, getRecommendations } from '/services/recommendations';

import { getProductOptions, linesFromVariants } from '/utils/product';
import { useEffect, useState } from 'react';

const CarouselProduct = ({ cart, product }) => {
	const globalStore = useGlobalStore();
	const [selectedOption, setSelectedOption] = useState();
	const [selectedTitle, setSelectedTitle] = useState();
	const [addToCartLoading, setAddToCartLoading] = useState(false);
	const sitewidePack = globalStore.getSitewidePack();

	const addToCart = async (variantId) => {
		setAddToCartLoading(true);
		const attributes = [{ key: MINI_CART_UPSELL, value: 'true' }];

		if (sitewidePack && VALID_SITEWIDE_PACK_TYPES.includes(product.productType)) {
			attributes.push({ key: SITEWIDE_PACK_BUILDER, value: 'true' });
			attributes.push({ key: PRODUCT_TYPE, value: product.productType });
			attributes.push({ key: BUILD_A_PACK, value: 'true' });
		}

		const line = {
			variantId,
			quantity: 1,
			attributes: attributes,
		};

		const lines = linesFromVariants([line]);

		const analyticsData = {
			price: product.upsellPrice || getFirstProperty(product, 'prices'),
			product: product,
			quantity: 1,
		};

		const variant = product.variants.find((variant) => {
			return variant.id === variantId;
		});

		if (variant) {
			analyticsData.variantId = variant.id;
			analyticsData.variantSku = variant.sku;
			analyticsData.variantTitle = selectedTitle;
		}

		await cart
			.addItems({
				analytics: [analyticsData],
				error: () => {
					setAddToCartLoading(false);
				},
				lines: lines,
				success: () => {
					setAddToCartLoading(false);
				},
			})
			.catch((e) => {
				setAddToCartLoading(false);
				logTryCatch(e);
			});
	};

	if (!product) {
		return null;
	}
	return (
		<div className="flex justify-center pb-3 border-1 border rounded-lg items-center aling-middle px-2 mx-1 bg-white">
			<div className="relative bg-gray-100 rounded-lg">
				<Image
					src={getFirstProperty(product, 'images', 'src')}
					alt={product.title}
					height={120}
					width={120}
					objectFit="contain"
				/>
				<div className="absolute -top-3 -left-2">
					<ProductType productType={product.productType} />
				</div>
			</div>

			<div className="flex-auto text-left basis-3/5 pl-2">
				<Typography
					variant="subtitle-sm"
					component="div"
					className="h-16 flex justify-center items-center">
					{getFirstProperty(product, 'titles')}
				</Typography>
				<div>
					{product.upsellPrice && (
						<>
							<Typography
								aria-hidden="true"
								className="line-through my-2 mr-2 text-gray-500"
								component="span"
								variant="small">
								<Price price={getFirstProperty(product, 'prices')} />
							</Typography>
							<Typography
								className="text-primary my-2 font-semibold"
								component="span"
								variant="small">
								<Price price={product.upsellPrice} />
							</Typography>
						</>
					)}
					{!product.upsellPrice && (
						<Typography className="my-2" component="span" variant="small">
							<Price price={getFirstProperty(product, 'prices')} />
						</Typography>
					)}
				</div>
				<div className="flex items-center align-middle justify-center">
					{product.options && getFirstProperty(product, 'options') && (
						<SelectGroup
							className="rounded-md"
							defaultValue={selectedOption?.value || ''}
							placeholder="Size"
							variant="short"
							name="Options"
							options={getProductOptions(product)}
							onChange={(val, title) => {
								setSelectedOption(val);
								setSelectedTitle(title);
							}}
						/>
					)}

					<Clickable
						color="primary"
						size="sm"
						disabled={!selectedOption}
						onClick={() => addToCart(selectedOption)}
						heapEventName="Add to Cart"
						heapEventData={{
							Source: 'Mini Cart',
							Product: product.handle,
							Price: product.upsellPrice || getFirstProperty(product, 'prices'),
						}}
						variant="naked"
						className="flex items-center justify-center focus:!shadow-none !p-1">
						<Icon name="cartCircle" height="30px" width="30px" />
						{addToCartLoading && <Loading height="16px" width="16px" className="ml-2" />}
					</Clickable>
				</div>
			</div>
		</div>
	);
};

export default function CartUpsell({ cart, processedCart }) {
	const [products, setProducts] = useState([]);

	const compareAtPricesMystery = {
		'mystery-boxers': DEFAULT_PRICE_MENS_UNDERWEAR_DECIMAL,
		'surprise-long-leg-ball-hammock-pouch-underwear': DEFAULT_PRICE_MENS_UNDERWEAR_DECIMAL,
		'the-mystery-thong-surprise-seamless-thong': DEFAULT_PRICE_WOMENS_THONG_DECIMAL,
		'surprise-cheeky-underwear': DEFAULT_PRICE_WOMENS_UNDERWEAR_DECIMAL,
	};

	useEffect(() => {
		async function fetchProducts() {
			let products = await getProductsShopify('minicart-upsell-temp');

			products = products.hits.map((product) => {
				if (product.productType === 'Gift Cards') {
					return product;
				}

				const upsellDiscount = getUpsellDiscount(product.vendor);
				if (product.titles[0].includes('Mystery')) {
					product.upsellPrice = product.prices[0];
					product.prices[0] =
						compareAtPricesMystery[product.handle] || DEFAULT_PRICE_MENS_UNDERWEAR_DECIMAL;
				} else {
					product.prices[0] = product.prices[0] * upsellDiscount;
				}
				return product;
			});
			setProducts(products);
		}

		async function fetchDynamicProducts() {
			const sourceProductIds = processedCart.processedLineItems?.map((lineItem) => {
				return lineItem.merchandise.product.id.replace('gid://shopify/Product/', '');
			});
			const internalResponse = await getRecommendations(sourceProductIds);

			const productPromise = internalResponse.map((item) => {
				return getProductById(item.shopifyProductId);
			});

			const res = await Promise.all(productPromise);
			setProducts(filterRecommendations(res));
		}
		if (processedCart.processedLineItems.length > 0) {
			fetchDynamicProducts().catch((e) => logTryCatch(e));
		} else {
			fetchProducts().catch((e) => logTryCatch(e));
		}
	}, [processedCart.processedLineItems]);

	return (
		<div className="rounded-md w-full overflow-hidden">
			<Typography variant="subhead-sm">Add Ons:</Typography>
			<Carousel dots={false} slidesToShow={1.2} infinite={false} className="px-10 slick-small">
				{products.map((p, i) => (
					<CarouselProduct cart={cart} product={p} key={i} />
				))}
			</Carousel>
		</div>
	);
}

CarouselProduct.propTypes = {
	cart: PropTypes.object,
	product: PropTypes.object,
};

CartUpsell.propTypes = {
	cart: PropTypes.object,
	processedCart: PropTypes.object,
};
