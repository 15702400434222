import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { getEstimatedMoneyBack } from '/services/static/promotions/money-back';
import { useGlobalStore } from '/state/global-context';
import { useEffect, useState } from 'react';

const MoneyBackCartDetail = ({ processedCart }) => {
	const [moneyBackDetails, setMoneyBackDetails] = useState();
	const globalStore = useGlobalStore();
	useEffect(() => {
		const moneyBack = getEstimatedMoneyBack(processedCart.subTotal);
		setMoneyBackDetails(moneyBack);
	}, [processedCart.subTotal]);

	const currentPromotion = globalStore.getPromotionalConfig();

	if (!currentPromotion || currentPromotion.promotionType !== 'money-back' || !moneyBackDetails) {
		return null;
	}

	return (
		<div
			className="text-center py-2 grid"
			style={{
				backgroundColor: currentPromotion.sitewideBanner.colorPrimary,
				color: currentPromotion.sitewideBanner.colorSecondary,
			}}>
			<Typography variant="subhead-sm">This Order Gets Money Back</Typography>
			<Typography>The more you buy the more you get back</Typography>
		</div>
	);
};

MoneyBackCartDetail.propTypes = {
	processedCart: PropTypes.object,
};

export default MoneyBackCartDetail;
