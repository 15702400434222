import Image from '/components/Image';
import Price from '../Price';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { getEstimatedMoneyBack } from '/services/static/promotions/money-back';
import { useGlobalStore } from '/state/global-context';
import { useEffect, useState } from 'react';

const MoneyBackCartLineItem = ({ processedCart }) => {
	const [moneyBackDetails, setMoneyBackDetails] = useState();
	const globalStore = useGlobalStore();
	const currentPromotion = globalStore.getPromotionalConfig();
	const isValidPromotion = currentPromotion && currentPromotion.promotionType === 'money-back';

	useEffect(() => {
		if (isValidPromotion) {
			const moneyBack = getEstimatedMoneyBack(processedCart.subTotal);
			setMoneyBackDetails(moneyBack);
		}
	}, [processedCart.subTotal]);

	if (!isValidPromotion || !moneyBackDetails || moneyBackDetails.estimate === 0) {
		return null;
	}
	//Delivered separately by email as store credit
	return (
		<div className="relative">
			<div className="grid grid-cols-4 mb-2 shadow-lg rounded-lg mx-5 border border-gray-100">
				<div className="flex p-2 text-center">
					<div className="h-[76px] ml-1 my-2 relative w-[100px]">
						<Image
							src="https://cdn.shinesty.com/2022-11-29/cash-bag.svg"
							alt="bag of cash"
							height={100}
							width={100}
							layout="fill"
							objectFit="contain"
							objectPosition="center"
						/>
					</div>
				</div>
				<div className="flex flex-col px-2 justify-start mt-4 col-span-3">
					<Typography className="font-semibold" component="p" variant="body">
						<Price price={moneyBackDetails.estimate} /> Money Back
					</Typography>
					<Typography className="italic text-tertiary-darker" component="p" variant="body">
						As store credit, sent by email within 3 business days
					</Typography>
				</div>
			</div>
		</div>
	);
};

MoneyBackCartLineItem.propTypes = {
	processedCart: PropTypes.object.isRequired,
};

export default MoneyBackCartLineItem;
