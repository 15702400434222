import Icon from '/components/IconUpdated';
import TierForm from '/components/promotions/stacking-product-modal/TierForm';
import Typography from '/components/Typography';
import dynamic from 'next/dynamic';
import { observer } from 'mobx-react-lite';
import { useCartStore } from '/state/cart-context';

import { useEffect, useState } from 'react';

const Modal = dynamic(() => import('/components/Modal'));
const ModalBody = dynamic(() => import('/components/Modal').then((mod) => mod.ModalBody));

const StackingProductModal = () => {
	const cartStore = useCartStore();

	const [modalOpen, setModalOpen] = useState(false);
	const [tiers, setTiers] = useState();
	const [totalTiers, setTotalTiers] = useState(0);

	useEffect(() => {
		if (!cartStore.promotionalProducts || cartStore.promotionalProducts.length === 0) {
			setModalOpen(false);
			setTotalTiers(0);
			return;
		}

		setModalOpen(true);
		setTiers(cartStore.plain().promotionalProducts);
		setTotalTiers(cartStore.promotionalProducts.length);
	}, [cartStore.promotionalProducts]);

	return (
		<Modal
			customType="stacking"
			locked={true}
			id="free-gift"
			isOpen={modalOpen}
			toggle={(val) => setModalOpen(val)}>
			<ModalBody>
				<div className="bg-white h-screen md:h-auto relative">
					<div className="flex flex-row p-4">
						<Icon name="giftBoxCircleRed" className="mr-4" height="38px" width="38px" />
						<div>
							<Typography
								variant="heading-sm"
								component="div"
								className="text-primary italic font-extrabold text-xl leading-6">
								Free Gift
							</Typography>
							<Typography component="div" variant="body">
								Nice, your cart qualified for free gifts. Choose whatchu want.
							</Typography>
						</div>
					</div>
					<TierForm
						tier={tiers && tiers[0]}
						allTiers={tiers}
						totalTiers={totalTiers}
						setModalOpen={setModalOpen}
					/>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default observer(StackingProductModal);
